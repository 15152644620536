.change-password, .new-password
  .subcontainer, .dataTable
    background: none
    border: none


.new-password
  width: pxToRem(940)
  margin: 0 auto
  td
    width: 100%
    input
      margin: 0
      padding: pxToRem(4)
      width: pxToRem(328)
      font-size: pxToRem(14)
      background: $white
      color: $shuttle-gray
      &:focus
        outline: pxToRem(2) solid $anakiwa
  .buttony
    width: pxToRem(330)
    padding: pxToRem(14)
    font-size: pxToRem(14)
    height: auto
    text-align: center
    float: none

  tr:first-child
    input
      border-top: pxToRem(1) solid $mercury
      border-radius: pxToRem(2) pxToRem(2) 0 0

table.simple.update-password
  th
    vertical-align: middle

.dataTable
  border: none
