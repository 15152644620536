.buttons-left
  display: flex
  flex-direction: row
  align-items: center

  .buttony
    margin-right: pxToRem(5)

.buttons-right
  display: flex
  flex-direction: row
  justify-content: flex-end
  align-items: center

  .buttony,
  .orcancel
    margin-left: pxToRem(5)

.buttons-react
  width: 100%
  margin-bottom: pxToRem(20)
  margin-right: pxToRem(10)
  clear: both

.buttons-react-invert
  margin: pxToRem(10) 0
  padding: pxToRem(10) pxToRem(13)
  background: $denim_05
  border-radius: pxToRem(2)

.buttony__container
  position: relative

.buttony__spinner
  position: absolute
  top: 50%
  left: 50%
  margin-top: -pxToRem(12)
  margin-left: -pxToRem(12)

.buttony__spinner--small
  margin-top: -pxToRem(12)
  margin-left: -pxToRem(12)
