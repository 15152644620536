.header-hover
  background-color: darken($denim, 2%)
  text-decoration: none

.header
  flex-shrink: 0

  a
    font-weight: bold
    color: $white
    &:hover, &:active
      color: $white
  .popup
    color: $mine-shaft
    float: left
    position: absolute
    top: pxToRem(45)
    left: pxToRem(110)
    width: $sidebar-width
    font-size: pxToRem(12)
    line-height: 1.1rem

    li
      list-style: none
      width: 100%
      &.muted
        img
          filter: grayscale(1)
      img
        width: 2rem
        vertical-align: middle
      .vault_switch_name
        display: inline-block
        vertical-align: middle
        width: 85%
      a
        font-weight: normal
        color: $ui
        &:hover
          color: $ui_hover
          img
            filter: hue-rotate(180deg)

.save_search
  display: inline
  .link_or_notice
    display: inline

.header-inner
  display: grid
  padding: pxToRem(10) pxToRem(25) 0 pxToRem(15)
  grid-template-columns: auto auto
  max-width: $max-width
  margin: auto

.header-title
  display: flex
  color: $white
  align-items: flex-end
  font-size: pxToRem(20)
  margin-left: pxToRem(40)

  a
    font-weight: normal
    &:hover
      text-decoration: none

.header-info
  display: flex
  white-space: nowrap
  align-items: flex-end
  justify-content: flex-end

  a.active, a.active:hover, a.active:active
    padding: pxToRem(2) pxToRem(3) pxToRem(1) pxToRem(3)
    background: $white
    color: $mine-shaft
    text-decoration: none

.header-actions
  display: inline-flex
  justify-content: flex-end

  .separator
    margin: pxToRem(13) pxToRem(5) 0
    float: left

.header-role
  float: right
  padding: pxToRem(20) 0 0
  font-size: pxToRem(12)

// Navigation Tabs
.header-tabs
  padding: pxToRem(15) pxToRem(40) 0

  .react_component
    a
      padding: 0!important
      margin-top: -3px

  a, .react_component
    float: left
    padding: pxToRem(10) pxToRem(15)
    border-radius: pxToRem(2) pxToRem(2) 0 0
    color: $link-water
    margin-right: pxToRem(5)

    &.disabled
      opacity: 0.5
    &:not(.disabled)
      &:hover
        text-decoration: none
        background: $ui !important
    &.active
      background: $white !important
      color: $mine-shaft
      &:hover
        background: $white !important
        color: $mine-shaft
    .newcount-count
      display: inline-block
      position: relative
      font-size: pxToRem(13)
      line-height: 1rem
      padding: 0 0 0 pxToRem(8)
      bottom: pxToRem(1)

#messages_tab
  .emailIcon
    display: inline-block
    background: url("../images/cdd30/icons/email.png") no-repeat 0 0
    background-size: pxToRem(16) pxToRem(48) !important
    height: pxToRem(12)
    width: pxToRem(16)
    position: relative

  &:hover .emailIcon
    background-position: 0 -pxToRem(16) !important
  &.active .emailIcon
    background-position: 0 -pxToRem(32) !important

// Headers
#userHeader, #vaultHeader, #inviteHeader
  background: linear-gradient(180deg, lighten($denim, 10%) 0%, lighten($denim, 7%) 40%, $denim 100%)
  color: $link-water

  .separator
    color: $danube

#inviteHeader
  height: pxToRem(50)

#logo
  margin-right: pxToRem(9)
  float: left
  padding-right: pxToRem(10)
  background: url("../images/cdd30/layout/header/title-separator.png") no-repeat 100% pxToRem(7)

#headerSwitcher-current
  float: left
  padding: 0 0 0 0
  font-size: pxToRem(20)
  line-height: pxToRem(20)
  font-weight: bold
  top: pxToRem(2)
  position: relative

  .actionIcon
    margin: 0 0 0 pxToRem(7)
    width: pxToRem(19)
    height: pxToRem(19)

#headerSwitcher
  padding: pxToRem(5)

#headerSwitcher:hover
  padding: pxToRem(5)
  background-color: darken($denim, 2%)

a#headerSwitcher-current:hover #headerSwitcher-current-title
  text-decoration: none

a#headerSwitcher-current:hover .actionIcon img
  top: -pxToRem(19)

#headerSwitcher-current-title
  float: left
  // for users with only one vault, and thus no link
  font-weight: bold

#minimal-header
  float: left
  height: pxToRem(30)
  width: 100%
  background: $denim
  color: $white
  font-size: pxToRem(13)
  line-height: pxToRem(13)
  .separator, .muted
    color: $hit-gray
  a, a:visited
    color: $white
    &:hover, &:active
      color: $white
  h1#minimal-header-title
    margin: 0 0 0 pxToRem(10)
    float: left
    padding: pxToRem(8) 0 0 0
    font-size: pxToRem(13)
    line-height: pxToRem(13)

// User info
.user-dropdown
  display: none
  background-color: $white
  min-width: pxToRem(150)
  border-radius: 0 0 pxToRem(2) pxToRem(2)
  box-shadow: 0 pxToRem(2) pxToRem(4) $black_20
  position: absolute
  top: pxToRem(90)
  right: pxToRem(10)
  z-index: 10
  ul
    margin: 0
    list-style-type: none
    padding: 0
    li
      margin: 0
      text-align: left
      padding: 0
      &:last-child a
        border-radius: 0 0 pxToRem(2) pxToRem(2)
      a, a.active
        display: block
        color: $denim
        background-color: $white
        padding: pxToRem(15) pxToRem(15)
        border-top: pxToRem(1) solid $black_10
        &:hover, &:active
          text-decoration: none
          background-color: $black-squeeze
          padding: pxToRem(15) pxToRem(15)
          border-top: pxToRem(1) solid $black_10
          color: $tory-blue

.logout-tab, .help-tab, .ai-chat-tab
  display: inline-block
  margin: 0 0 0 pxToRem(3)
  float: left
  line-height: 1rem
  a
    display: inline-block
    cursor: pointer
    padding: pxToRem(13) 0 0
    color: $link-water
    border-radius: pxToRem(2) pxToRem(2) 0 0
    &:hover
      color: $white
      text-decoration: none
    &:active
      color: $white
      text-decoration: none

.username-tab
  margin-right: -pxToRem(15)

  a
    display: inline-block
    cursor: pointer
    padding: pxToRem(8) pxToRem(15)
    color: $link-water
    border-radius: pxToRem(2) pxToRem(2) 0 0
    &:hover
      @extend .header-hover // sass-lint:disable-line placeholder-in-extend
    &:active
      text-decoration: none
      background: $tory-blue
  a.selected
    background: $tory-blue
    color: $white

.username-text
  display: inline-block
  max-width: pxToRem(300)
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  vertical-align: middle

.username-text__admin
  max-width: pxToRem(130)

.subhead
  display: block
  margin: -pxToRem(10) 0 pxToRem(15) 0
