.container
  .main
    width: $show-right-column-width
  .sidebar
    width: $sidebar-width

.container-content
  clear: both
  padding: pxToRem(24) 0

.container-content-inner
  margin: 0 0 pxToRem(36) 0

.container-toolbar
  float: left
  width: calc(100% - pxToRem(10))
  border-bottom: pxToRem(1) solid $mercury
  .left
    float: left
  .right
    float: right
  .item
    float: left
    padding: pxToRem(9) pxToRem(15) 0 pxToRem(15)
    height: pxToRem(30)
    border: pxToRem(0) solid $mercury
    line-height: pxToRem(20)
  .borderLeft
    border-left-width: pxToRem(1)
  .borderRight
    border-right-width: pxToRem(1)
  strong
    padding: 0 pxToRem(5) 0 0

.container-tabs
  a
    display: block
    float: left
    height: pxToRem(12)
    white-space: nowrap
    height: pxToRem(50)
    .label
      padding: pxToRem(18) pxToRem(0) pxToRem(18) pxToRem(19)
      color: $shuttle-gray
      float: left
      overflow: hidden
      text-overflow: ellipsis
      line-height: 1rem
      height: pxToRem(12)
      border-bottom: pxToRem(2) solid transparent
      max-width: pxToRem(120)
    .count
      color: $danube
      padding: pxToRem(18) pxToRem(19) pxToRem(18) pxToRem(5)
      font-weight: normal
      float: right
      line-height: 1rem
      height: pxToRem(12)

      &.fake-count
        width: pxToRem(0)
        height: pxToRem(12)
        padding-left: pxToRem(0)
    &:hover
      .count
        text-decoration: none
        border-bottom: pxToRem(2) solid $anakiwa
      .label
        border-bottom: pxToRem(2) solid $anakiwa
        text-decoration: none
        color: $outer-space
    &.active, &.active:hover
      .count
        border-bottom: pxToRem(2) solid $ui
      .label
        font-weight: bold
        border-bottom: pxToRem(2) solid $ui
        color: $ui
  .newcount
    color: $ui_hover
    padding: 0 0 0 pxToRem(3)
  a:hover .count
    color: $steel-blue

.container-tab
  display: none

.container-tab-active
  display: block

.edit-access-popup
  display: none
  background: url("../images/cdd30/layout/overlay-20.png") repeat top left
  padding: pxToRem(4)
  min-width: pxToRem(360)
  position: fixed
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

  #edit-access-content
    background-color: $white
    padding: pxToRem(20)

    h2
      margin-top: pxToRem(0)

    form.access-form
      table
        padding: 0 pxToRem(20)

        tr :first-child
          padding-right: pxToRem(20)

        th, td
          height: 3rem
          align-items: center

        td
          justify-content: right

    .buttons-right
      margin-top: pxToRem(20)

    .orcancel
      padding-top: pxToRem(6)

// Sub-container

.subcontainer,
.subsubcontainer
  padding: pxToRem(24) pxToRem(24) pxToRem(9) pxToRem(24)
  background: $alabaster
  border: pxToRem(1) solid $gallery
  border-radius: pxToRem(2)

.subcontainer--right-spaced
  margin-right: pxToRem(24)


.subcontainer--text-only
  padding: pxToRem(24)

.additional
  margin: pxToRem(10) 0 0 0
