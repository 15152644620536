%hr
  margin: pxToRem(25) 0
  clear: both
  border-bottom: pxToRem(1) solid
  border-bottom-color: $mercury

%title-size
  font-size: pxToRem(32) !important
  line-height: pxToRem(48) !important

// wrap words into separate lines
%word__wrap
  word-break: break-word // does not work for edge or windows ff
  overflow-wrap: break-word
  white-space: normal

// break up words into separate lines
%break-word
  word-break: break-all
  word-break: break-word // does not work for edge or windows ff
  white-space: normal

// max character
%max-char
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  max-width: 100%

// structureless molecule preview
%molecule-img__structureless
  display: flex
  margin: 10%
  align-items: center
  justify-content: center
  width: 60%
  height: 60%
  padding: 10%
  border-radius: 50%
  border: pxToRem(2) dotted $denim_25

  p
    margin: 0
    color: $denim_50
    text-align: center
    text-transform: uppercase

// form inputs
%form-input
  padding: pxToRem(4)
  background: $white
  border: pxToRem(1) solid $alto
  font-size: pxToRem(12)
  border-radius: pxToRem(2)
