.dataTable

  // batch fields, eln fields, molecule fields
  &#batchFieldDefinitions-show,
  &#elnFieldDefinitions-show,
  &#moleculeFieldDefinitions-show,
  &#protocolFieldDefinitions-show,
  &#runFieldDefinitions-show,
  &.dataTable-new

    margin: pxToRem(10) 0 pxToRem(20)
    border: 0

    thead
      border-bottom: pxToRem(1) solid $th-border

    th
      color: $th
      padding: pxToRem(10)
      border: 0
      background: none

    tbody > tr
      border-bottom: pxToRem(1) solid $border

      &:nth-last-of-type(1)
        border: 0

    td
      color: $color
      padding: pxToRem(10)
      border: 0

  &#elnFieldDefinitions-show
    th, td
      &:nth-last-of-type(1)
        display: none

.fakeDataTable
  margin: pxToRem(10) 0 pxToRem(20)
  border: 0

  .row
    border-bottom: pxToRem(1) solid $border

    &.greyBackground
      border-bottom: pxToRem(1) solid $th-border
      color: $th

    .inner
      padding: pxToRem(10) 0 pxToRem(10) pxToRem(10)

    .inlineEditForm
      padding: pxToRem(25) pxToRem(30) pxToRem(20) pxToRem(30)

  .row.fake_data_table__add_row
    border-bottom: none

  .row.draggable
    .inner
      padding: pxToRem(10) 0 pxToRem(10) pxToRem(10)

  .actions
    float: right
    white-space: nowrap

  table .actions
    float: none

  form
    .floatRight
      margin-top: pxToRem(25)
