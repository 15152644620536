#footer
  flex-shrink: 0
  width: 100%

  a
    color: $hit-gray

    &:hover
      color: $regent-gray

    &:active
      color: $hit-gray

  .separator
    color: $hit-gray

#footer-inner
  margin: pxToRem(25)
  padding: pxToRem(25) 0
  border-top: pxToRem(1) solid $gallery

#footer-left
  float: left
  width: 50%
  font-weight: bold

#footer-right
  float: right
  width: 50%
  text-align: right
