.dataTable
  position: inherit
  margin-bottom: pxToRem(15)
  margin-top: pxToRem(25)
  width: 100%
  border-top: pxToRem(1) solid $gallery
  border-collapse: collapse

  p:last-child
    margin-bottom: 0

  th, td
    padding: pxToRem(15) pxToRem(10)
    text-align: left
    vertical-align: middle
    border-top: pxToRem(1) solid $gallery
    background: transparent
    &.count
      text-align: right
  tr
    &:first-child
      th, td
        border-top: none
    th, td
      background: transparent

  .extra
    td, th
      border-top: none
      padding-top: 0

  td.inlineEditForm
    padding: pxToRem(25) pxToRem(30) pxToRem(20) pxToRem(30)

  thead th
    padding: pxToRem(15) pxToRem(10)
    white-space: nowrap
    text-align: left
    font-weight: normal
    color: $th
    color: $th
    border: none
    background: $alabaster
    vertical-align: bottom
    border-bottom: pxToRem(1) solid $gallery
    &.sorted
      font-weight: bold
      color: $shuttle-gray
      .icon-10
        margin: 0 0 0 pxToRem(4)
      a
        color: $shuttle-gray
    &.count
      text-align: right

  tbody th
    white-space: normal

  .details
    font-weight: normal
    font-size: pxToRem(10)
    color: $regent-gray

  tbody td > p:first-child
    margin-top: -pxToRem(2)

  table.simple
    margin: 0
    tr, th, td
      background: transparent
    th, td
      padding: 0 0 pxToRem(10) pxToRem(10)
      border-top: none
    tbody th
      padding-left: 0
      text-align: left
      white-space: nowrap

// Data set selection
.selectSnapshots
  .dataTable
    margin: pxToRem(20) 0 0 0

#user-projects
  margin: 0
  td, th
    vertical-align: middle
  th
    font-size: pxToRem(14)
  .user-projects-toggle
    padding-right: pxToRem(5)
    width: pxToRem(120)

#user-snapshots
  margin: 0
  tbody th, td
    padding: pxToRem(15) pxToRem(10)
  tbody th
    font-size: pxToRem(14)
  tbody td.user-snapshots-toggle
    padding-right: pxToRem(5)
    width: pxToRem(120)
  .extra th, .extra td
    padding: 0 pxToRem(10) pxToRem(10) pxToRem(10)
    p
      margin: 0 0 pxToRem(10) 0
      font-size: pxToRem(11)
      line-height: 1.2rem
      color: $dim-gray
