@use "@fontsource/noto-sans/scss/mixins" as NotoSans

// Fonts
@include NotoSans.faces($directory: "~@fontsource/noto-sans/files", $subsets: all, $weights: all, $styles: all)

$fa-font-path: "~font-awesome/fonts"
@import '../../../node_modules/font-awesome/scss/font-awesome.scss'
@import '../../../node_modules/react-contexify/dist/ReactContexify.css'

// Base
@import base/variables
@import base/placeholders
@import base/mixins
@import base/elements

// Layout
@import layout/content
@import layout/footer
@import layout/header
@import layout/wrapper
@import layout/wide

// Vendor Overrides : Material
@import vendor/material/react_component
@import vendor/material/button
@import vendor/material/datatable
@import vendor/material/form

// Components
@import components/buttons
@import components/container
@import components/data-table
@import components/form
@import components/grid
@import components/loading

// Partials
@import partials/new-user
@import partials/password
@import partials/prelogin
