@media (max-width: pxToRem(1200))
  .grid
    grid-template-columns: repeat(2, 50%)!important

.grid
  display: grid
  grid-template-columns: repeat(3, 33.3%)
  grid-gap: 0.5rem
  font-size: $font-size
  line-height: 1.25

.grid__item
  display: flex
  margin: pxToRem(1) // pxToRem(1) for border
  cursor: pointer
  background: $white
  padding: 0 0.5rem
  text-decoration: none
  align-items: center

  &:hover
    margin: 0
    border: pxToRem(1) solid $denim
    border-radius: $border-radius
    box-shadow: 0 pxToRem(2) pxToRem(4) $box-shadow
    text-decoration: none

    // if no synonym
    em.muted
      text-decoration: none
      color: $color

.grid__thumbnail
  margin: 1rem
  width: 9rem
  min-width: 9rem
  height: 9rem
  &.no_structure
    transform: scale(0.75)
  svg
    width: pxToRem(126)
    height: pxToRem(126)

.grid__details
  @extend %break-word
  padding: 1rem

  p
    margin: 0
    color: $color

    &:nth-of-type(1)
      margin-top: 0.25rem

  em.muted
    color: $color
    opacity: 0.5

.grid__title
  margin: 0
  color: $denim
  height: auto

.grid__subtitle
  font-size: pxToRem(14)
  color: $dusty-gray
