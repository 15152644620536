.wrapper__prelogin
  display: flex
  height: 100vh
  align-items: center
  justify-content: center

  h2
    margin: 0 auto pxToRem(25)
    width: pxToRem(272)
    border: 0
    border-radius: pxToRem(2)
    background: $white
    color: $pale-sky
    font-size: pxToRem(12)
    font-weight: normal
    line-height: 1.4
    text-align: center
    a
      color: $pale-sky
      font-weight: bold
      &:hover
        text-decoration: underline

  .header-logo
    margin: 0 auto
    text-align: center
    width: pxToRem(300)
    height: auto
    img
      margin-bottom: pxToRem(30)
      width: pxToRem(150)

  .credentialsForm
    margin: 0
    height: pxToRem(200)
    width: pxToRem(300)
    font-size: pxToRem(10)
    text-align: left

  // Automatic new account creation
  table.credentialsTable.newAccountTable
    th
      display: none

  .credentialsTable
    width: pxToRem(300)
    border: 0
    border-spacing: 0
    input
      margin: 0
      border-radius: pxToRem(2)
      font-size: pxToRem(14) !important
    .input_text
      color: $shuttle-gray
      padding: pxToRem(14) !important
      width: pxToRem(270)
      height: pxToRem(18)
      border: pxToRem(1) solid $mercury
      background-color: transparent
      &#username
        border-radius: pxToRem(2) pxToRem(2) 0 0
      &#password
        border-top: 0
        border-radius: 0 0 pxToRem(2) pxToRem(2)
        z-index: 10
        padding: pxToRem(13) pxToRem(14) pxToRem(15)
      &:focus
        outline: pxToRem(2) solid $anakiwa
    .details td
      width: pxToRem(136)
      font-size: pxToRem(12)
      text-align: right
      padding: pxToRem(10) 0 0 0
      &:first-child
        text-align: left
    .buttony
      margin: pxToRem(20) 0 pxToRem(20)
      padding: pxToRem(14)
      height: pxToRem(46)
      width: 100%
      float: none
      border: 0
      background: $ui
      &:hover
        cursor: pointer
        background: $denim


  #events
    margin: pxToRem(30) pxToRem(10) pxToRem(80) pxToRem(10)
    height: pxToRem(80)
    width: pxToRem(300)
    font-size: pxToRem(12)
    background: $peach
    text-align: center
    border: pxToRem(2) solid $pearl-bush

  .errorMessage, .warningMessage, .successMessage
    margin: 0 auto pxToRem(25)
    width: pxToRem(266)
    border: 0
    border-radius: pxToRem(2)
    padding: pxToRem(14) pxToRem(17) pxToRem(1)

  .errorMessage, .warningMessage, .successMessage
    & + center > table
      margin-top: 0

  .container-content // Public access registration page
    padding: 0

#new_registration
  margin: 0 pxToRem(150) 0 pxToRem(150)
  text-align: center
  table
    border-spacing: 0
  h2
    margin: 0 auto pxToRem(15)
    width: pxToRem(272)
    border: 0
    border-radius: pxToRem(2)
    background: $white
    color: $pale-sky
    font-size: pxToRem(14)
    font-weight: normal
    line-height: 1.4
    text-align: center
    a
      color: $pale-sky
      font-weight: bold
      &:hover
        text-decoration: underline
  .uneditable td
    padding-top: pxToRem(10)
  #details
    display: none
  .container
    margin: 0 auto
    background: $white
    border: 0
    width: pxToRem(300)
    padding: 0
  table.wideForm
    width: pxToRem(300)
  th
    display: none
  input, select
    margin: 0
    border-radius: 0
    font-size: pxToRem(14)
    border: pxToRem(1) solid $mercury
    border-top: 0
    background: $white
    color: $shuttle-gray
    &:focus
      outline: pxToRem(2) solid $anakiwa
  input
    padding: pxToRem(14)
    height: pxToRem(18)
    width: pxToRem(270)
  select
    height: pxToRem(46) !important
    width: pxToRem(300)
    outline: none
    appearance: none
    padding-left: pxToRem(14) !important
    border-top: none !important
  td
    padding: 0
  tr:nth-child(2)
    td
      border-top: pxToRem(1) solid $mercury
      border-radius: pxToRem(2) pxToRem(2) 0 0
  .buttony
    margin: pxToRem(20) 0 pxToRem(30)
    width: pxToRem(270)
    padding: pxToRem(14)
    height: pxToRem(18)
    border-radius: pxToRem(2)
    text-align: center
    font-size: pxToRem(14)
    border: 0
  .terms
    color: $regent-gray

#registration_snapshot_id
  width: pxToRem(410)

// Footer

#copyright
  color: $gray
