.input-radio
  margin-left: 0
  padding-left: 0
  padding-bottom: 0
  vertical-align: top

.input-checkbox
  margin: 0 pxToRem(3) 0 0
  padding: 0
  vertical-align: bottom
  position: relative
  top: -pxToRem(1)

.input-text
  @extend %form-input

.input-text-date
  width: pxToRem(125) !important
  vertical-align: middle

.input-text-medium
  font-size: pxToRem(11)
  padding: pxToRem(2) !important

.input-text-small
  font-size: pxToRem(12) !important
  padding: pxToRem(4) !important
  margin-bottom: -pxToRem(6)

.inlineRadios
  .input-radio
    padding-top: 0
    padding-bottom: 0
    margin-top: 0
    margin-bottom: 0
  label
    padding-right: pxToRem(10)

// Date range with calendars
.dateSelection
  display: inline-block

  .buttony-tiny
    padding: pxToRem(6) pxToRem(20) pxToRem(3)
    margin-top: pxToRem(1)



// Inline edit form

.inlineEditForm

  .subcontainer
    width: auto


form a,button
  order: 0

// used in TestableSelect to allow MUI controls to be accessed as standard select elements
.testable-select-container
  .select-control
    max-height: 2px
    select
      max-height: 2px
      opacity: .2
      width: 100%
  .mui-control
    > div
      width: 100%
