// Buttons

.buttony
  float: left
  padding: pxToRem(8) pxToRem(15)
  text-wrap: nowrap
  font-size: 1rem
  line-height: 1rem
  font-weight: bold
  color: $white
  background-color: $ui
  border-radius: pxToRem(4)
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)
  border: none
  &:hover
    text-decoration: none
    color: $white
    background-color: $denim
  &:active
    background-color: $denim

.buttony-green
  background-color: $green_button
  &:hover
    background-color: $green_button_hover
  &:active
    background-color: $green_button_hover

.buttony-red
  background-color: $color_warning
  &:hover
    background-color: $cardinal
  &:active
    background-color: $cardinal

.buttony-small
  padding: pxToRem(6) pxToRem(12)
  height: auto
  font-size: pxToRem(12)

.buttony-tiny
  display: inline-block
  padding: 0.5rem 0.6rem 0.35rem 0.6rem
  float: none
  font-size: pxToRem(10)
  text-align: center
  vertical-align: middle

.buttony
  &.disabled,
  &.disabled:hover,
  &.disabled:active
    background-color: $aqua-haze
    color: $loblolly
    cursor: default


.orcancel
  float: left
  padding: pxToRem(9) 0 pxToRem(9) pxToRem(6)

.orcancel-small
  padding: pxToRem(6) 0 0 pxToRem(3)

.buttony-description-small
  display: inline-block
  padding: pxToRem(6)

.ui-widget-content a.buttony
  color: $white
