// border radius
=border-radius($radius)
  border-radius: $radius

=border-radius-top-right($radius)
  border-top-right-radius: $radius

=border-radius-bottom-right($radius)
  border-bottom-right-radius: $radius

=border-radius-bottom-left($radius)
  border-bottom-left-radius: $radius

=border-radius-top-left($radius)
  border-top-left-radius: $radius

=border-radius-top($radius)
  +border-radius-top-left($radius)
  +border-radius-top-right($radius)

=border-radius-right($radius)
  +border-radius-top-right($radius)
  +border-radius-bottom-right($radius)

=border-radius-bottom($radius)
  +border-radius-bottom-right($radius)
  +border-radius-bottom-left($radius)

=border-radius-left($radius)
  +border-radius-top-left($radius)
  +border-radius-bottom-left($radius)

// browser detection
=browser($browsers)
  @each $browser in $browsers
    html[data-browser*="#{$browser}"] &
      @content

// Normally 16px is 1rem, but our (smallest) base font size is 14 so we'll scale accordingly
$remMultiplier: calc(1 / 14)

@function pxToRem($value)
  $remValue: ($value * $remMultiplier) + rem
  @return $remValue

@function -pxToRem($value)
  $remValue: -($value * $remMultiplier) + rem
  @return $remValue

@function em($value)
  $emValue: ($value * $remMultiplier) + em
  @return $emValue

@function -em($value)
  $emValue: -($value * $remMultiplier) + em
  @return $emValue
