html
  margin: 0
  padding: 0
  font-size: #{$size}px // rem
  min-height: 100% // keeps footer on the bottom of the viewport with small content

@media (max-width: 1050px)
  html
    font-size: #{$size}px

@media (min-width: 1050px)
  html
    font-size: #{$size + 1}px

body
  margin: 0
  padding: 0
  font-size: $font-size // em
  font-family: $font-family
  color: $shuttle-gray
  background: $white
  min-height: 100% // keeps footer on the bottom of the viewport with small content
  min-width: pxToRem(1024) // force hscroll when window is narrow

// text
h1
  margin: pxToRem(10) 0 0
  font-size: pxToRem(24)
  line-height: 2.5rem
  font-weight: normal

h2
  margin: 0 0 1.25rem
  font-size: pxToRem(16)
  font-weight: normal

h3
  margin: 0 0 1.25rem
  font-size: pxToRem(14)
  line-height: 1.25rem
  font-weight: normal

h4
  margin: 0 0 pxToRem(5)
  font-size: pxToRem(12)
  height: pxToRem(12)
  font-weight: normal

h5
  margin: 0
  font-size: pxToRem(11)
  font-weight: normal

p
  margin: 0 0 1.25rem
  line-height: 1.4rem

ul
  margin: 0 0 1.25rem
  padding: 0 0 0 1.25rem

li
  margin: 0 0 pxToRem(5)
  padding: 0

// img
img
  border: none

// form
form
  margin: 0
  padding: 0

input[type="password"]:not(.material-input)
  &.input-text.error
    border: thin $guardsman-red solid

input[type="text"]:not(.material-input)
  margin: 0
  font-size: pxToRem(12)
  border: pxToRem(1) solid $mercury
  background: $white
  color: $shuttle-gray
  border-radius: pxToRem(2)
  padding: pxToRem(4)
  &.input-text.error
    border: thin $guardsman-red solid

  &:focus
    outline: pxToRem(2) solid $anakiwa

  &:disabled
    color: $silver_dark
    background-color: $alabaster !important

select
  vertical-align: baseline
  border: pxToRem(1) solid $alto !important
  padding: pxToRem(2) pxToRem(32) pxToRem(4) pxToRem(4) !important
  height: pxToRem(26) !important
  outline: none
  appearance: none
  background: $white url('../images/cdd30/arrow-bg.png') no-repeat 100% 50% !important
  background-size: 2rem 2rem !important
  font-size: pxToRem(14)
  color: $shuttle-gray
  border-radius: pxToRem(2)
  max-width: 50rem
  &.error
    border: thin $guardsman-red solid !important

  &:focus
    outline: pxToRem(2) solid $anakiwa

  &:disabled
    background: $alabaster url('../images/cdd30/arrow-bg-disabled.png') no-repeat 100% 50% !important
    background-size: 2rem 2rem !important
    color: $silver_dark

textarea
  @extend %form-input
  resize: vertical
  &#message_body.error
    border: thin $guardsman-red solid
  &#reply_body.error
    border: thin $guardsman-red solid
  &#molecule_smiles.error
    border: thin $guardsman-red solid
  &#formatted_formula_custom_calculation.error
    border: thin $guardsman-red solid

// Links & buttons
a,button,.pseudo-link
  color: $ui
  text-decoration: none
  cursor: pointer

  &:hover,
  &:hover .muted
    color: $denim
    text-decoration: underline

  &:active
    color: $denim

  &:focus
    outline: none

  &.muted,
  .muted
    color: $viking

    &:hover
      color: $ui
      text-decoration: underline

    &:active
      color: $ui

  &.cancel,
  &.beta
    color: $color_warning

    &:hover
      color: $cardinal
      text-decoration: underline

    &:active
      color: $color_warning
      background: none

  &.disabled
    color: $dusty-gray

    &:hover
      color: $dusty-gray
      cursor: default
      text-decoration: none
      background: none

    &:active
      color: $dusty-gray

// table
table, thead, tbody, tr, th, td
  margin: 0
  padding: 0
  border: none
  font-size: pxToRem(12)
  font-family: helvetica, arial, sans-serif
  border-spacing: 0
