.link__defaultLabel
  font-size: pxToRem(16)

.material-form-popup-card
  overflow: visible !important

// textarea
.form__textarea

  > div
    font-size: pxToRem(12)

  p
    font-size: pxToRem(14)
    opacity: 0.75

.form__textarea__old > div
  padding: pxToRem(4)
  background: $white
  border: pxToRem(1) solid $alto
  border-radius: pxToRem(2)

  &:before,
  &:after
    display: none

  textarea
    line-height: 1.25
    color: $shuttle-gray
    padding-top: pxToRem(1)

// created focus state due to edge not supporting :focus-within
.form__textarea__focused > div
  outline: pxToRem(2) solid $anakiwa

.form__textarea__old
  border: none !important
  padding: 0 !important
