.wrapper
  display: flex
  flex-direction: column
  min-height: 100%
  min-width: pxToRem(990)
  width: 100%

  .ribbon-wrapper
    width: pxToRem(110)
    height: pxToRem(110)
    overflow: hidden
    position: fixed
    top: pxToRem(0)
    z-index: 1000
    pointer-events: none

  .ribbon
    font: bold pxToRem(16) arial
    color: $white
    text-align: center
    font-variant: small-caps
    transform: rotate(-45deg)
    position: relative
    padding: pxToRem(5) 0
    left: -pxToRem(46)
    top: pxToRem(25)
    width: pxToRem(180)
    background-color: $vermilion
