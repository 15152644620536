// Global ajax indicator
#globalAjax
  display: none

.global-loading
  +border-radius-bottom($border-radius)
  display: flex
  margin-left: -4rem
  position: fixed
  top: 0
  left: 50%
  width: 8rem
  height: 2.5rem
  background: $ui_hover
  z-index: 10000
  justify-content: space-around
  align-items: center
  box-shadow: 0 0 0.5rem $black_40
  color: $white
  padding: 0.25rem 0.5rem

.global-loading__text
  margin: 0
  font-size: 1.125rem
  line-height: 1
  text-transform: uppercase

#globalSlurpIndicator
  td
    padding-right: pxToRem(8)
    white-space: nowrap


// Asynchronous loading
.asyncLoading
  padding: pxToRem(20) pxToRem(40) pxToRem(40) pxToRem(40)
  text-align: center
  font-size: pxToRem(20)
  color: $tundora
  img
    margin: 0 pxToRem(10) 0 0
    vertical-align: pxToRem(3)
