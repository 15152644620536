#content
  flex: 1 0 auto
  padding: 0 0 pxToRem(20) 0
  width: 100%
  background: $white
  margin-top: -pxToRem(1) // fix gap on Firefox

.displayed_form_content
  max-width: $max-narrow-form-width

#content-inner
  margin: 0 auto // delete after full width design is implemented
  max-width: $max-width
  padding: pxToRem(25)

  // delete after full width design is implemented
  &.content-inner__full-width
    margin: unset
    width: unset

.section
  margin: 0 0 pxToRem(30) 0

.aside
  background: $denim_05
  padding: pxToRem(6)
  border-radius: $border-radius

.sidebar-container
  display: flex

  .main
    flex-grow: 1

  .right-sidebar
    margin: 0 0 0 pxToRem(20)
    padding: 0
    width: $sidebar-width

  .sidebar
    margin: 0 pxToRem(20) 0 0
    flex-shrink: 0
    padding: 0
    width: $sidebar-width

.sidebar
  .message, .container
    padding: pxToRem(18) pxToRem(20) pxToRem(4) pxToRem(20)

.hr
  @extend %hr

.thumbnail
  padding: pxToRem(1)
  border: pxToRem(1) solid transparent
  border-radius: pxToRem(2)
  a:hover &
    border-color: $ui
  &.no_structure
    transform: scale(0.75)

#registered_compounds
  .thumbnail.no_structure
    transform: none

.inline
  display: inline-block

.clear
  clear: both

.spaced
  margin: pxToRem(15) 0 !important

.spaced_top
  margin-top: pxToRem(30)

.nomargin
  margin: 0 !important

.floatLeft
  float: left !important
  margin-left: pxToRem(10)

.floatRight
  float: right !important
  margin-right: pxToRem(10)

.margin-bottom-5px
  display: block
  margin-bottom: pxToRem(5)

.small-text-block
  overflow: auto
  max-height: 5.5rem

.space__left
  margin-left: pxToRem(6)

.space__left__2x
  margin-left: pxToRem(12)

.text__center
  text-align: center !important

.text__right
  text-align: right !important

.vertical-align__top
  vertical-align: top !important

.vertical-align__bottom
  vertical-align: bottom !important

// Two column

.twoColumn
  float: left
  width: 100%
  background: url("../images/cdd30/layout/vertical-rule-90.gif") repeat-y 50% 0
  .column1
    margin: pxToRem(10)
    float: left
    width: 47%
  .column2
    margin: pxToRem(10)
    float: right
    width: 47%

// Skipnav

#skipnav
  position: absolute
  top: -1000rem
  left: -1000rem

.separator
  padding: 0 pxToRem(3)
  color: $silver

#bottomRight
  padding-left: pxToRem(6)
  padding-top: pxToRem(6)

#bottomLeft
  padding-top: pxToRem(2)

.width__full
  width: 100%

.height__full
  height: 100%

.flex-row
  display: flex
  flex-direction: row

.hidden
  display: none

.lowest-opacity
  opacity: 0.00001

.float-right, .float__right
  float: right

.whitespace__nowrap
  white-space: nowrap

.no-pointer-events
  pointer-events: none

.overflow-visible
  overflow: visible
