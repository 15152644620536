@import mixins

$max-width: pxToRem(2400)
$sidebar-width: pxToRem(300)
$max-narrow-form-width: pxToRem(1024)
$show-right-column-width: calc(100% - pxToRem(300))


// fonts
$font-family: helvetica, arial, sans-serif

//pixels
$size: 14

// em
$font-size: pxToRem($size)

$container-width: pxToRem(900)

// color palette

// colors : http://chir.ag/projects/name-that-color/
$catskill-white: rgb(230, 236, 242) // #e6ecf2
$gray-nurse: rgb(229, 234, 229) // #e5eae5
$loblolly: rgb(182, 190, 198) // #B6BEC6
$hit-gray: rgb(165, 176, 187) // #A5B0BB
$cadet-blue: rgb(163, 177, 191) // #a3b1bf
$regent-gray: rgb(125, 139, 153) // #7D8B99
$pale-sky: rgb(108, 118, 128) // #6C7680
$shuttle-gray: rgb(84, 93, 102) // #545D66
$outer-space: rgb(47, 52, 57) // #2F3439

$alice-blue: rgb(247, 251, 255) // #F7FBFF
$aqua-haze: rgb(240, 244, 247) // #F0F4F7
$black-squeeze: rgb(245, 249, 252) // #f5f9fc
$black-squeeze_dark: rgb(235, 242, 247) // #ebf2f7
$link-water: rgb(207, 223, 239) // #CFDFEF
$solitude: rgb(229, 244, 255) // #e5f4ff
$link-water: rgb(217, 232, 242) // #d9e8f2
$pattens-blue: rgb(222, 240, 255) // #def0ff
$botticelli: rgb(213, 227, 237) // #d5e3ed
$ship-cove: rgb(110, 147, 184) // #6E93B8
$anakiwa: rgb(151, 212, 255) // #97D4FF
$lochmara: rgb(0, 119, 204) // #07c
$steel-blue: rgb(70, 130, 180) // #4682B4
$viking: rgb(102, 173, 224) // #66ade0
$danube: rgb(102, 153, 204) // #6699cc
$polo-blue: rgb(129, 163, 198) // #81A3C6
$fountain-blue: rgb(96, 151, 191) // #6097bf
$bermuda-gray: rgb(103, 137, 171) // #6789ab
$tropical-blue: rgb(199, 228, 249) // #c7e4f9
$periwinkle-gray: rgb(201, 221, 235) // #C9DDEB
$spindle: rgb(172, 202, 234) // #accaea
$curious-blue: rgb(34, 148, 230) // #2294e6
$curious-blue_dark: rgb(31, 133, 207) // #1f85cf
$astral: rgb(51, 111, 171) // #336fab
$denim: rgb(18, 98, 179) // #1262b3
$denim_05: rgba(18, 98, 179, 0.05)
$denim_25: rgba(18, 98, 179, 0.25)
$denim_50: rgba(18, 98, 179, 0.5)
$tory-blue: rgb(13, 85, 156) // #0D559C
$science-blue: rgb(0, 96, 191) // #0060bf
$dodger-blue: rgb(51, 119, 255) // #3377ff
$dodger-blue_light: rgb(77, 166, 255) // #4da6ff
$azure-radiance: rgb(16, 126, 236) // #107eec
$dark-blue: rgb(0, 0, 170) // ##00A
$venice-blue: rgb(6, 72, 138) // #06488A
$blue: rgb(0, 0, 255) // #0000FF
$cerulean: rgb(9, 178, 225) // #09B2E1

$apple-green: rgb(217, 241, 227) // #D9F1E3
$turquoise: rgb(71, 228, 194) // #47e4c2

$rice-flower: rgb(244, 255, 230) // #f4ffe6
$frost: rgb(248, 252, 245) // #F8FCF5
$loafer: rgb(240, 247, 235) // #F0F7EB
$orinoco: rgb(242, 252, 227) // #f2fce3
$deco: rgb(184, 217, 152) // #b8d998
$feijoa: rgb(174, 215, 118) // #aed776
$olivine: rgb(157, 196, 128) // #9DC480
$la-rioja: rgb(173, 204, 20) // #adcc14
$atlantis: rgb(119, 204, 51) // #77cc33
$lima: rgb(126, 211, 33) // #7ED321
$lima_dark: rgb(113, 194, 25) // #71C219
$malachite: rgb(56, 230, 53) // #38e635
$green: rgb(0, 191, 0) // #00BF00
$japanese-laurel: rgb(0, 166, 0) // #00A600
$japanese-laurel_dark: rgb(0, 128, 0) // #008000
$sushi: rgb(123, 178, 45) // #7bb22d
$limeade: rgb(85, 170, 0) // #5a0
$limeade_dark: rgb(97, 166, 0) // #61A600
$lime: rgb(0, 255, 0) // #00FF00
$olive-drab: rgb(96, 128, 32) // #608020
$fern-frond: rgb(102, 102, 34) // #662

$pearl-bush: rgb(229, 224, 207) // #e5e0cf
$citrine-white: rgb(249, 240, 212) // #f9f0d4
$mint-julep: rgb(242, 226, 194) // #f2e2c2
$sandal: rgb(160, 131, 105) // #A08369
$varden: rgb(255, 248, 220) // #FFF8DC

$chilean-heath: rgb(255, 253, 229) // #FFFDE5
$dolly: rgb(255, 255, 136) // #ffff88
$turbo: rgb(242, 222, 0) // #f2de00
$sunglow: rgb(255, 204, 51) // #ffcc33
$sun: rgb(255, 170, 17) // #ffaa11

$sidecar: rgb(242, 226, 192) // #f2e2c0
$peach: rgb(255, 228, 181) // #ffe4b5
$mango-tango: rgb(238, 119, 0) // #ee7700
$tango: rgb(242, 133, 24) // #f28518
$tenn: rgb(204, 102, 0) // #c60
$burnt-orange: rgb(204, 85, 0) // #cc5500
$jambalaya: rgb(89, 50, 22) // #593216

$vermilion: rgb(255, 69, 0) // #FF4500

$chablis: rgb(255, 242, 242) // #fff2f2
$serenade: rgb(255, 240, 230) // #fff0e6
$pippin: rgb(255, 229, 229) // #ffe5e5
$your-pink: rgb(255, 204, 204) // #ffcccc
$sandy-beach: rgb(255, 234, 204) // #ffeacc
$cashmere: rgb(229, 189, 161) // #e5bda1
$petite-orchid: rgb(228, 161, 155) // #e4a19b
$coral-tree: rgb(170, 102, 102) // #aa6666
$roman: rgb(217, 98, 98) // #d96262
$sea-pink: rgb(234, 144, 144) // #ea9090
$pink: rgb(250, 109, 125) // #fa6d7d
$well-read: rgb(170, 51, 51) // #aa3333
$amaranth: rgb(230, 54, 76) // #e6364c
$amaranth_10: rgba(230, 54, 76, 0.1)
$amaranth_50: rgba(230, 54, 76, 0.5)
$red-orange: rgb(255, 51, 51) // #ff3333
$red: rgb(255, 0, 0) // #f00
$red_dark: rgb(230, 0, 0) // #e60000
$monza: rgb(221, 0, 0) // #dd0000
$guardsman-red: rgb(204, 0, 0) // #c00
$valencia: rgb(217, 54, 54) // #d93636
$bright-red: rgb(170, 0, 0) // #aa0000
$cardinal: rgb(200, 35, 55) // #C82337

$violet-eggplant: rgb(171, 34, 171) // #ab22ab

// grayscale
$alabaster: rgb(247, 247, 247) // #f7f7f7
$alabaster_80: rgba(247, 247, 247, 0.80)
$concrete: rgb(242, 242, 242) // #F2F2F2
$gallery: rgb(238, 238, 238) // #eee
$mercury: rgb(230, 230, 230) // #e6e6e6
$alto: rgb(221, 221, 221) // #ddd
$silver: rgb(204, 204, 204) // #ccc
$silver_dark: rgb(187, 187, 187) // #bbb
$silver-chalice: rgb(170, 170, 170) // #aaa
$dusty-gray: rgb(153, 153, 153) // #999
$gray: rgb(136, 136, 136) // #888
$boulder: rgb(119, 119, 119) // #777
$dove-gray: rgb(102, 102, 102) // #666
$dim-gray: rgb(97, 97, 97) // #616161
$emperor: rgb(85, 85, 85) // #555
$tundora: rgb(68, 68, 68) // #444
$mine-shaft: rgb(51, 51, 51) // #333
$cod-gray: rgb(26, 26, 26) // #1A1A1A
$black: rgb(0, 0, 0) // #000
$light-gray: rgb(224, 224, 224) // #E0E0E0

// black
$black_05: rgba(0, 0, 0, 0.05)
$black_08: rgba(0, 0, 0, 0.08)
$black_10: rgba(0, 0, 0, 0.10)
$black_20: rgba(0, 0, 0, 0.20)
$black_40: rgba(0, 0, 0, 0.40)
$black_42: rgba(0, 0, 0, 0.42)
$black_54: rgba(0, 0, 0, 0.54)
$black_75: rgba(0, 0, 0, 0.75)
$black_87: rgba(0, 0, 0, 0.87)

// white
$white: rgb(255, 255, 255) // #fff
$white_50: rgba(255, 255, 255, 0.5) // #fff

// vendor

// material
$material-label: $black_54
$material-bg_hover: $black_08
$material-hr: $black_42
$material-bg: $black_08

// color scheme

// interaction
$ui: $lochmara
$ui_focus: $anakiwa
$ui_hover: $tenn
$ui_hover-old: $denim

// element
$th: $regent-gray
$th-border: $shuttle-gray
$header-border: $mercury

// property
$color: $shuttle-gray
$color_brand: $denim
$color_warning: $amaranth
// $color_error:

$bg: $catskill-white
$bg_warning: $pippin

$border: $catskill-white
$border_dark: $silver
$border_hover: $denim
$border-radius: pxToRem(2)

$green_button: $limeade
$green_button_hover: rgb(77, 153, 0)


$box-shadow: $catskill-white
