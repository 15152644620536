#new_user
  th
    white-space: nowrap
  .main
    float: left
  .section
    width: pxToRem(590)
  .container-content, container-content-inner
    margin: 0
    padding: 0
  th.vault
    vertical-align: middle
  .account-select__control
    margin-bottom: .5rem

#new_member
  input.input-text
    margin: pxToRem(3) 0
  td
    background: transparent
